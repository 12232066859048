import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_2 = { class: "fev_text5" }
const _hoisted_3 = {
  key: 0,
  class: "title"
}
const _hoisted_4 = {
  key: 1,
  class: "title"
}
const _hoisted_5 = {
  key: 2,
  class: "d-flex align-items-center"
}
const _hoisted_6 = {
  key: 0,
  class: "radiusInfo bg_experience"
}
const _hoisted_7 = {
  key: 1,
  class: "radiusInfo category"
}
const _hoisted_8 = { class: "fw_700 nowrap" }
const _hoisted_9 = {
  key: 3,
  class: "audioContainer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_love_face_shadow_bar = _resolveComponent("love-face-shadow-bar")
  const _component_show_experience_period_selected = _resolveComponent("show-experience-period-selected")
  const _component_audio_player = _resolveComponent("audio-player")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("span", _hoisted_2, _toDisplayString(_ctx.experience.creator.text) + _toDisplayString(_ctx.getAge), 1),
      (_ctx.affinityPercentage > 0)
        ? (_openBlock(), _createBlock(_component_love_face_shadow_bar, {
            key: 0,
            class: "justify-content-center small",
            text: _ctx.affinityPercentage + '%'
          }, null, 8, ["text"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.experience.title)
      ? (_openBlock(), _createBlock("span", _hoisted_3, _toDisplayString(_ctx.experience.title), 1))
      : (_openBlock(), _createBlock("span", _hoisted_4, "---")),
    (_ctx.experience.period.title || _ctx.experience.category)
      ? (_openBlock(), _createBlock("div", _hoisted_5, [
          (_ctx.experience.period.title)
            ? (_openBlock(), _createBlock("div", _hoisted_6, [
                _createVNode(_component_show_experience_period_selected, {
                  periodSelected: _ctx.experience.period.title
                }, null, 8, ["periodSelected"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.experience.category)
            ? (_openBlock(), _createBlock("div", _hoisted_7, [
                (_ctx.experience.category.iconUrl)
                  ? (_openBlock(), _createBlock("img", {
                      key: 0,
                      src: _ctx.experience.category.iconUrl
                    }, null, 8, ["src"]))
                  : _createCommentVNode("", true),
                _createVNode("span", _hoisted_8, _toDisplayString(_ctx.$localizationService.getTextFromValues(_ctx.experience.category.title.values)), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.experience.audioSrc)
      ? (_openBlock(), _createBlock("div", _hoisted_9, [
          _createVNode(_component_audio_player, {
            source: _ctx.experience.audioSrc
          }, null, 8, ["source"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}