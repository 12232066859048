
import { Options, Vue } from 'vue-class-component';
import { ExperienceClient } from '@/services/Services';
import CardShadowInfo from '../components/experience/cardShadowInfo.vue';
import bodymovin from 'lottie-web';
import * as OM from '@/Model';

@Options({
    components: {
        CardShadowInfo
    }
})
export default class ExperienceListByCreator extends Vue {

    personName: string = "";
    experienceList: OM.ExperienceDetailVM[] = [];

    created() {
        var creatorId = this.$route.params.creatorIdentifier.toString();
        ExperienceClient.getAllActiveListByOwner(creatorId)
        .then(x => {
            this.experienceList = x;
            this.personName = this.experienceList[0].creator.text;
        })
    }

    mounted(){
        setTimeout(() => {
            var experienceLoop = bodymovin.loadAnimation({
                container: <any>this.$refs.experienceLoop,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './json/experience_loop.json',
            })

            experienceLoop.play();
        }, 300);
    }

}
