
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import ShowExperiencePeriodSelected from './showExperiencePeriodSelected.vue';
import * as OM from '@/Model';

@Options({
    components: {
        ShowExperiencePeriodSelected
    }
})
export default class CardShadowInfo extends Vue {

    @Prop({
        default: () => new OM.ExperienceDetailVM()
    }) experience: OM.ExperienceDetailVM;

    @Prop({
        default: 0
    }) affinityPercentage: number;

    created(){
    }

    get getAge(){
        if(!this.experience.creatorBirthDate)
            return "";

        return ", " + this.$filters.age(this.experience.creatorBirthDate);
    }

}
